export default {
    navPc: ['About Us', 'Casino', 'Contact Us'],
    navH5: ['About Us', 'Activity', 'Information'],
    navH52: ['Free Play', 'Contact Us'],
    navH53: ['Jackpot', 'Venue', 'Games', 'What is Jackpot'],
    footerH5: ['Jackpot', 'Venue', 'Games', 'What is Jackpot', 'About Us', 'Activity', 'Information', 'Free Play'],
    desc1: 'Free Play',
    desc2: 'Live Slots，Winning On-The-Roads',
    desc3: 'Innovators in the Industry',
    desc4: 'We are dedicated to bringing the most popular slot machine games from casinos around the world online. Featuring the largest number of machines and a wide variety of progressive jackpots. Experience the same operational feel as offline, with an online gaming experience that surpasses the real thing.',
    jackpotData: [
        { desc: 'Total Jackpot Amount', number: '10', unit1: 'Million+', unit2: '$' },
        { desc: 'No of Progressive Jackpots', number: '37', unit1: '', unit2: '' },
    ],
    venueData: [
        { desc: 'Machines in Operation，Continuously Increasing', number: '4000', unit1: '+', unit2: '' },
        { desc: 'Offline Venue Size', number: '20000', unit1: '+', unit2: ' Sqm' },
    ],
    connectData: [
        { desc: 'Online Casino Partners', number: '28', unit1: '', unit2: '' },
        { desc: 'Supported Languages', number: '9', unit1: '', unit2: '' },
    ],
    mapData: [
        {desc: 'Online Casino Partners', number: '28', unit1: '', unit2: ''},
        {desc: 'Total Jackpot Amount', number: '10', unit1: 'Million+', unit2: '$'},
        {desc: 'No of Progressive Jackpots', number: '37', unit1: '', unit2: ''},
        {desc: 'No of Games', number: '217', unit1: '', unit2: ''},
        {desc: 'Professional Employees', number: '1000', unit1: '+', unit2: ''},
        {desc: 'Machines in Operation，Continuously Increasing', number: '4000', unit1: '+', unit2: ''},
        {desc: 'Offline Venue Size', number: '20000', unit1: '+', unit2: ' Sqm'},
        {desc: 'Supported Languages', number: '9', unit1: '', unit2: ''},
    ],
    mapDesc: [
        ' SHUFFLE MASTER - Known for "card shufflers," the company has released popular games like the Oriental-themed "Golden Choice" series',
        'SCIENTIFIC GAMES - A renowned slot machine company, offering progressive upgrades like "Treasure Chest" and popular games like the "JIN JI BAO XI" series jackpot',
        ' IGT - With a long history as a top global slot machine company, IGT\'s Egyptian-themed games are beloved by players. Egypt symbolizes mystery and adventure; let\'s explore together',
        'Buffalo slot games are themed around wildlife and natural landscapes, offering various winning combinations and high jackpots, taking you on a journey through different sceneries',
        ' DA FU DA GUI series features themes based on Chinese lucky symbols, closely related to Asian cultural designs, and are particularly popular among players, such as the "Eternal Happiness" game',
        ' With rich themes and generous bonuses, especially games related to Chinese culture, these games are highly favored, such as "Fa Fa Fa" and "God of Wealth',
        'Coin Combo offers various animal-themed games combined with Asian culture, featuring three progressive treasure chest upgrades that players love',
        'Lightning Link - Provides various themes and large jackpot slot games, including popular Oriental-themed games',
        'ARUZE GAMING - A leading Japanese game company offering pachinko machines, slot machines, and other popular games',
        'ARISTOCRAT - Australia\'s largest slot machine game company, launching several global games such as the popular 5 Dragons series'
    ],
    desc6: 'Our Offline Venues',
    desc7: 'Our six major offline venues can support tens of thousands of slot machines, with 24/7 monitoring to ensure a smooth gaming experience. We welcome you to visit our venues.',
    desc8: 'iSLOT offers hundreds of physical slot machine games, featuring unique play styles like free spins with multiplier rewards and massive Jackpot amounts.',
    desc9: 'The Official Partnership Model of iSLOT',
    desc10: 'As a reputable brand leading international trends, iSLOT has developed multiple online platforms tailored to diverse customer needs. We have established global base stations to ensure seamless entertainment',
    desc11: 'offering unique experiences and collaboration opportunities.',
    desc12: 'Business Partner',
    cooperateBtn: ['Rakeback', 'API Integration'],
    desc13: 'Rakeback Affiliate：',
    desc14: 'Commission-based Affiliate：',
    cooperateDesc: [
        '1. Affiliates receive rakebacks based on the total bets of their affiliate line (excluding their own account).',
        '2.Rakeback commissions are settled every Monday.',
        '3.Rakeback commissions will be credited to the affiliate\'s account wallet.',
        '4.Weekly rakeback effective bets are calculated according to the game.Effective bets in the iSLOT venue are converted at a 1:1 ratio, whereas effective bets in other electronic game venues are converted at a 1:0.35 ratio'
    ],
    cooperateDesc2: [
        'Example: An affiliate deposits, 1,000,000U to the platform and gets a 60% affiliate share, in this case',
        'The affiliate deposits 1,000,000U and receives 2,500,000 chips',
        'The profit from selling the chips to sub-affiliate is entirely owned by the affiliate.',
    ],
    desc15: 'Tier',
    desc16: 'Weekly Valid Bets （USDT）',
    desc17: 'Affiliate Rakeback iSLOT',
    desc18: 'Affiliate  Tier',
    desc19: 'Affiliate Share',
    desc20: 'Single Deposit USDT',
    desc21: 'Chips',
    desc22: 'Cooperation Advantages',
    desc23: 'Affiliate Responsibilities',
    desc24: [
        '1. Low Startup Threshold: Affiliates purchase codes at a discount for maximum profit.',
        '2. Secure Payment Channels: Efficient and low-cost official payment channels.',
        '3. Flexible Management: Affiliates can set rebate strategies for sub-affiliates.',
        '4. Low-Risk Earnings: Official coverage of Grand Jackpot costs at a 1:1 ratio.',
    ],
    desc25: [
        '1. Recharging to obtain chips and backend accounts.',
        '2. Managing deposits, withdrawals, and payment settlements for sub-agents.',
    ],
    desc26: 'API Integration',
    desc27: [
        '1. A platform fee of 45% GGR is charged.',
        '2. Choose to integrate machines with the Grand Jackpot.Upon integration, 2% of the betting amount must be paid as prize money.with the Grand Jackpot covered by iSLOT.',
        '3. Each buy-in requires a minimum prepayment of 100,000 to cover platform fees and prize money payments！'
    ],
    desc28: 'iSLOT operates globally, focusing 100% on slot machine innovation and dedicated to excellent customer service.',
    desc29: 'With advanced technology and a commitment to integrity, we offer various cooperation methods and are always ready to assist you.',
    desc30: 'Name',
    desc31: 'Please enter your name',
    desc32: 'Email (Required)',
    desc33: 'Please fill in your email',
    desc34: 'Location（optional）',
    desc35: 'Please fill in your location',
    desc36: 'Type of Inquiry',
    desc37: 'Please fill inquiry type',
    desc38: 'Submit Inquiry',
    desc39: 'Please fill in your inquiry details.',
    desc40: 'API cooperation',
    desc41: 'Affiliate cooperation',
    desc42: 'Deep cooperation',
    desc43: 'other issues',
    desc44: 'Submit',
    desc45: 'Official email：',
    desc46: 'Sent successfully',
    desc47: 'Please complete the information.',
    desc48: 'Please enter a valid email.',
    desc49: 'LIVE GRAND JACKPOT',
    next: 'Next：',
    '游戏数量': 'Game Count',
    '查看更多信息': 'view more',
    '其他游戏': 'Other Game',
    '游戏类型': 'Game Type',
    '特殊玩法': 'Special Gameplay',
    '免费试玩': 'Free Play',
    desc50:'Restricted Access',
    desc403:'We apologize for the inconvenience, but our policy prohibits accessing our site from your location'
}
