import React from 'react';
import ReactDOM from 'react-dom/client';
import Home from '@/pages';
import '@/utils/entry';
import { RouterProvider } from 'react-router-dom';
import {router} from './router'
import { RecoilRoot } from 'recoil';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RecoilRoot><RouterProvider router={router} /></RecoilRoot>);
