import axios from "axios";
import CryptoJS from "crypto-js";
import { toast } from "@/utils/toast";
import { locale } from "../i18n";

export const arr = num => {
    let arr = []
    for (let i = 0; i < num; i++) {
        arr.push(i)
    }
    return arr
}

export const img = url => {
    return require(`@/assets/img${url}`)
}

export const formatNum = val => {
    const num = Number(val)
    if (num || num === 0) {
        return num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    } else {
        return val
    }
}

const { agent, key, autoSpin, customerUrl, jackpotType, singleGame, eAgent, eKey, confServer } = window.config
let agentVal = agent
let keyVal = key

const post = (url, data = {}, agent, key) => {
    const params = {
        timestamp: new Date().getTime(),
        ...data
    }
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(params), CryptoJS.MD5(key), {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    }).ciphertext.toString()
    return axios.post(`${url}?agent=${agent}`, encrypted, {
        headers: {
            'Content-Type': 'text/plain',
        }
    })
}

const post2 = (apiKey, data = {}, agent, key) => {
    const params = {
        timestamp: new Date().getTime(),
        ...data
    }
    return axios.post(`https://islot.com/islotGame/web/lobby/v2/${apiKey}`, data, {
        headers: {
            'Content-Type': 'application/json',
        }
    })
}

function objectToQueryString(obj) {
    const queryString = [];
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const encodedKey = encodeURIComponent(key);
            const encodedValue = encodeURIComponent(obj[key]);
            queryString.push(`${encodedKey}=${encodedValue}`);
        }
    }
    return queryString.join('&');
}

export const getLocationParams = key => {
    const search = window.location.search.slice(1)
    const params = {}
    search.split('&').forEach(el => {
        const arr = el.split('=')
        params[arr[0]] = arr[1]
    })
    return key ? params[key] : params
}

export const getJackpot = () => post('/api/v1/febGetJackpots', {}, agent, key)

let requesting = false

const loginCallBack = (url) => {
    window.open(url, '_blank')
}
//跳转大厅
const fetchLobbyUrl = (params, gameData, callback = loginCallBack) => {
    // 使用 URLSearchParams 提取参数
    // I0001-大厅，I0002-游戏
    // const urlParams = new URLSearchParams(params);
    // const singleGame = urlParams.get('singleGame');
    // const newParam = singleGame == 2 ? "&p=I0001" : "&p=I0002";

    return post('/api/v2/gameEntrance', params, agentVal, keyVal).then(res => {
        const { code, data, message } = res.data
        return new Promise((resolve, reject) => {
            if (code === '200') {
                setTimeout(() => {
                    const urlArr = data.gameUrl.split('/')
                    urlArr[0] = window.location.protocol
                    urlArr[2] = window.location.host + '/playGame'
                    const url = urlArr.join('/')

                    //跳转对应奖池游戏
                    if (gameData.gameName || gameData.linkName) {
                        const gameUrl = `${url}&gameName=${gameData.gameName}&linkName=${gameData.linkName}`;
                        callback(gameUrl)
                    } else {
                        // //跳转默认
                        callback(url)
                    }
                    requesting = false
                }, 300);
            } else {
                toast(message)
                reject()
            }
            requesting = false
        })
    }).catch(() => {
        toast('Server Error')
        requesting = false
    })
}

//跳转游戏
const getGameUrl = (params, type) => {
    // 使用 URLSearchParams 提取参数
    // I0001-大厅，I0002-游戏
    // const urlParams = new URLSearchParams(params);
    // const singleGame = urlParams.get('singleGame');
    // const newParam = singleGame == 2 ? "&p=I0001" : "&p=I0002";

    return new Promise((resolve, reject) => {
        axios.get(`/api/v1/gameEntrance?${params}`).then(res => {
            const { code, data, message } = res.data
            if (code === '200') {
                // setTimeout(() => {
                //     const urlArr = data.gameUrl.split('/')
                //     urlArr[0] = window.location.protocol
                //     urlArr[2] = window.location.host + (type === '0' ? '/playGame' : '')
                //     const url = urlArr.join('/')
                //     window.open(url, '_blank')
                //     // window.open(data.gameUrl, '_blank');
                //     requesting  = false
                // }, 300);
                const urlArr = data.gameUrl.split('/')
                urlArr[0] = window.location.protocol
                urlArr[2] = window.location.host + (type === '0' ? '/playGame' : '')
                const url = urlArr.join('/')
                requesting = false
                resolve(url)
            } else {
                toast(message)
                requesting = false
                reject()
            }
        })
    })
}

export const sendEmail = (params, callback) => {
    requesting = true

    return post('/api/v1/sendEmail', params, agent, key).then(res => {
        const { code, message } = res.data
        return new Promise((resolve, reject) => {
            if (code === '200') {
                callback && callback()
            } else {
                toast(message)
                reject()
            }
            requesting = false
        })
    }).catch(() => {
        toast('Server Error')
        requesting = true
    })
}

export const login = (gameData = {}, callback) => {
    if (!requesting) {
        requesting = true

        let agentVal, keyVal;
        let Ewallet = getLocationParams('Ewallet');

        agentVal = Ewallet ? eAgent : agent
        keyVal = Ewallet ? eKey : key

        const randomNumber = Math.floor(Math.random() * 1000) + 1;
        const userName = 'ISlot_' + randomNumber.toString().padStart(3, '0')
        const params = { currency: 'USD', userName, password: '******', aliasName: userName }
        const gameParams = { lang: localStorage.getItem('Language') || locale, singleGame: '0', type: '0' }
        post('/api/v1/userLogin', params, agentVal, keyVal).then(res => {
            const { code, data, message } = res.data
            if (code === '200') {
                let _data = {
                    agent: agentVal,
                    ticket: data.token,
                    currency: params.currency,
                    callbackUrl: window.location.href,
                    jackpotType: jackpotType,
                    autoSpin,
                    customerUrl,
                    // singleGame: (gameParams.slotId && singleGame === '0') ? '1' : singleGame,
                    singleGame: gameParams.slotId ? '1' : (gameParams.type === '1' ? '0' : singleGame),
                    ...gameParams
                }

                if (Ewallet)
                    _data.userToken = data.token;
                else
                    _data.ticket = data.token;

                //进厅改为post请求
                if (_data.singleGame != '1') {
                    fetchLobbyUrl(_data, gameData, callback)
                } else {
                    getGameUrl(objectToQueryString(_data), gameParams.type).then(res => callback && callback(res)).catch(() => { })
                }
            } else {
                toast(message)
                requesting = false
            }
        }).catch(() => {
        })
    }
}

//获取所有配置数据
export const getConfigurationData = (params, callback) => {
    const lang = localStorage.getItem('Language') || locale
    params = `id=${lang.toUpperCase()}Version&version=v1.0.7`;
    return new Promise((resolve, reject) => {
        axios.get(`${confServer}/api/web/jsoneditor?${params}`).then(res => {
            callback(res.data.data.info);
        })
    })
}

//获取所有tdk配置数据
export const getTdkConfigData = (callback) => {
    let params = `id=iotdk&version=v1.0.1`;
    return new Promise((resolve, reject) => {
        axios.get(`${confServer}/api/web/jsoneditor?${params}`).then(res => {
            callback(res.data.data.info);
        })
    })
}

export const getTdkData = (route, callback) => {
    let tdkData = null;
    let router = route
    getTdkConfigData(val => {
        const lang = localStorage.getItem('Language') || locale
        tdkData = val.TDK[lang][router];
        callback(tdkData)
    });
}

export const setTdk = (tdkdata, callback) => {
    let tdk = tdkdata || {
        title: "iSLOT - Live Slot , Winning On-the-Roads",
        description: "The World’s Largest Online Live Slot Machine Platform, Offering Authentic and Compliant Remote-Controlled Slot Machines with Synchronized Video. With Prize Pools Exceeding Millions of Dollars and Hundreds of the Most Popular Land-Based Slot Machines Available on the iSLOT Platform",
        keywords: "Slot Machine, Live Slot Machine, Slot, Live Dealer, Big Wins,  Road Map, Free Trial"
    };

    document.title = tdk.title;
    
    document.querySelector("meta[name='title']")?.setAttribute('content', tdk.title);
    document.querySelector("meta[property='og:title']")?.setAttribute('content', tdk.title);
    document.querySelector("meta[name='al-title']")?.setAttribute('content', tdk.title);

    document.querySelector("meta[name='description']")?.setAttribute('content', tdk.description);
    document.querySelector("meta[property='og:description']")?.setAttribute('content', tdk.description);

    document.querySelector("meta[name='keywords']")?.setAttribute('content', tdk.keywords);
    document.querySelector("meta[property='og:keywords']")?.setAttribute('content', tdk.keywords);
}


export const getJackpotList = (callback) => {
    requesting = true

    return post('/api/v1/getJackpots', { productId: 'IS1' }, agent, key).then(res => {
        const { code, message } = res.data
        return new Promise((resolve, reject) => {
            if (code === '200') {
                callback && callback(res.data)
            } else {
                toast(message)
                reject()
            }
            requesting = false
        })
    }).catch(() => {
        toast('Server Error')
        requesting = true
    })
}

export const isExternalLink = (url) => {
    const protocols = ['http://', 'https://', 'ftp://'];
    return protocols.some(protocol => url.startsWith(protocol));
}

export const scrollToView = (className) => {
    const dom = document.getElementsByClassName(className)[0]
    if (!dom) return
    const top = dom.offsetTop - 40
    document.getElementById('root').scrollTo({ top, behavior: 'smooth' })
}
