import { useEffect, useState, Suspense } from "react";
import { Outlet } from "react-router-dom";
import { getConfigurationData } from "@/utils/func";
import { useSetRecoilState } from "recoil";
import { dataState, screenState, gameUrlState, langState } from '../store';
import { login } from "@/utils/func";
import { locale } from "../i18n";

function App() {
    const [isH5, setIsH5] = useState(null)
    const setData = useSetRecoilState(dataState);
    const setScreen = useSetRecoilState(screenState);
    const setGameUrl = useSetRecoilState(gameUrlState);
    const setLang = useSetRecoilState(langState)

    useEffect(() => {
        setLang(locale || 'en')
        login({}, val => {
            setGameUrl(val)
        })
        getConfigurationData(locale, val => {
            setData(val)
        });
    }, [])

    useEffect(() => {
        const setRootFontSize = () => {
            let width = window.innerWidth
            const max = 1280
            const min = 366
            width = width > max ? max : width < min ? min : width;
            let size = 100 * width / 375
            const root = document.documentElement
            root.style.fontSize = size + 'px';
        }
        const onResize = () => {
            const IsH5 = window.innerWidth < 1280
            if (IsH5 !== isH5) {
                setIsH5(IsH5)
                setScreen(IsH5)
                localStorage.setItem('isH5', String(IsH5))
                document.getElementsByTagName('html')[0].setAttribute('class', IsH5 ? 'H5' : 'PC')
            }
            if (IsH5) {
                setRootFontSize();
            } else {
                document.documentElement.style.fontSize = ''
            }
        }
        onResize()
        window.addEventListener('resize', onResize)
        return () => {
            window.removeEventListener('resize', onResize)
        }
    }, [isH5])

    return (
        <Suspense>
            {
                isH5 !== null && <Outlet />
            }
        </Suspense>
    );
}

export default App;
